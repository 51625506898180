import React, { useEffect, useMemo } from 'react';

const embedScripts = {
  // Form for mainbody.component-003: *Senior* GadgetRanker
  1: {
    id: "qs-embed-67de24faab41316f0b3fc6f2",
    src: "https://form.questionscout.com/qs-form-script.min.js",
    formId: "67dd74c4c41947f388b9b4e2",
    label: "GadgetRanker_Senior"
  },
  // Form for mainbody.component-001: Main GadgetRanker
  2: {
    id: "qs-embed-67de412283311fc337049cd8",
    src: "https://form.questionscout.com/qs-form-script.min.js",
    formId: "67de40e5c41947b297ba8271",
    label: "GadgetRanker_Main"
  },
  // Form for mainbody.component-XXX: Smart Devices Lead Gen
  3: {
    id: "qs-embed-67df2daed36c4df5f615b294",
    src: "https://form.questionscout.com/qs-form-script.min.js",
    formId: "67df27b0c41947824abad92e",
    label: "SeniorFinancialGuide_Main"
  },
  // Form for mainbody.component-XXX: Smart Devices Lead Gen (New)
  4: {
    id: "qs-embed-67e59d3b7ec62013bb306fbd",
    src: "https://form.questionscout.com/qs-form-script.min.js",
    formId: "67e593a7c41947352fbfd026",
    label: "SmartDevices_Main"
  }

};

const EmailSignupBlock = ({ signupId }) => {
  const embed = useMemo(() => embedScripts[signupId], [signupId]);

  useEffect(() => {
    if (!embed) return;

    const script = document.createElement('script');
    script.src = embed.src;
    script.setAttribute("data-form-id", embed.formId);
    script.setAttribute("data-url-params", "[]");
    script.setAttribute("data-runner-id", embed.id);
    script.setAttribute("data-css", "");
    script.setAttribute("data-scale", "true");
    document.body.appendChild(script);

    return () => {
      const existing = document.querySelector(`script[data-runner-id="${embed.id}"]`);
      if (existing) existing.remove();
    };
  }, [embed]);

  return <div id={embed?.id} />;
};

export default EmailSignupBlock;
