import React, { useMemo } from 'react';
import './ConclusionBlock.css';

const ConclusionBlock = ({ conclusionId }) => {
  const conclusionData = useMemo(() => [
    {
      id: 1,
      copy: `✅ <b>Helping You Live Smarter, Feel Better, and Stay Independent</b><br/><br/>We hope you found this guide helpful and maybe even discovered a few tools that could make a real difference in your daily life.<br/><br/>We’re always on the lookout for practical, proven, and affordable solutions that make life better for people 55 and over. So if you found this helpful, take a moment to:<br/><br/>👉 Share this article with a friend, family member, or caregiver who might benefit too<br/>👉 Check out our other expert-curated guides for senior savings, wellness, and lifestyle upgrades<br/>👉 Or better yet, sign up below to get exclusive offers and product updates delivered to your inbox`
    },
    {
      id: 2,
      copy: `✅<b>You're One Step Closer to Smarter Senior Living</b><br/><br/>Thanks for exploring our guide! We hope these recommendations help make daily life simpler and more enjoyable.<br/><br/>👉 Don’t forget to share this with a loved one<br/>👉 Explore more of our curated senior tips<br/>👉 Sign up below to never miss another update!`
    },
    {
      id: 3,
      copy: `✅<b>You Deserve to Feel Like You Again</b><br/><br/>If you’ve been feeling a little out of sync—mentally, physically, or emotionally—these wellness picks were made for exactly that. Each product on this list was selected for its ability to help real people find balance, clarity, and calm again.<br/><br/>Whether it’s a better night’s sleep, a more comfortable body, or a brain that feels sharper than it has in years—*you can absolutely feel better.*<br/><br/>👉 Share this with someone else who could use a lift<br/>👉 Or sign up below to get more trusted recommendations and exclusive offers from the FinesseFixers team<br/><br/>We test what works. We skip what doesn’t. And we only share solutions we believe are worth your time—and your trust.
`
    }
    // Add more blocks here
  ], []);

  const selectedConclusion = useMemo(() => {
    return conclusionData.find(c => c.id === conclusionId);
  }, [conclusionId, conclusionData]);

  if (!selectedConclusion) return null;

  return (
    <div className="conclusion-section">
      <div className="conclusion-copy" dangerouslySetInnerHTML={{ __html: selectedConclusion.copy }} />
    </div>
  );
};

export default ConclusionBlock;
