import { Divider } from '@material-ui/core'
import React from 'react'
// import author from '../../assets/author-img.jpg'
// import heroImg from '../../assets/hero_img.jpg'
import './intro.style.css'

export default function Intro({...o}) {

// FUNCTION FOR A DATE 2-4 DAYS AGO
const daysAgo = 3; // Change this to 2, 3, or 4 for different days
const current = new Date();
current.setDate(current.getDate() - daysAgo); // Subtract 'daysAgo' days

const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
const date = `${months[current.getMonth()]} ${current.getDate()}, ${current.getFullYear()}`;

console.log(date); // This will log the date 3 days ago (change daysAgo value to modify)

 

    return (
            <div className="intro_sec">
                <h1>{o.introTitle}</h1>
                <div className="intro_image">
                    <img src={o.introImage} alt="introductory"/>
                </div>
                <div className="intro_details">
                    <div className="author_image">
                        <img src={o.authorImage} alt="author" />
                    </div>
                    <div>
                        <p className="aut_d">
                            Written by <span>{o.authorName}</span> <span className="date">{date}</span> <span className="views">{o.pageViews}</span>
                        </p>
                    </div>
                </div>

                <div className="intro_text"> 
                    <p dangerouslySetInnerHTML={{ __html: o.introTexts }} />
                </div>
                <Divider />
            </div>
        )
}
